import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { recuperaSenha } from '../../services/mysql'

export default function EsqueciSenha() {
    const [email, setEmail] = useState('')
    const [cpf, setCpf] = useState('')
    const [senha, setSenha] = useState('')

    function esqueciSenha() {
        if (!email) {
            mensagemAlerta('Digite seu Email')
            return
        }
        if (!cpf) {
            mensagemAlerta('Digite seu CPF')
            return
        }
        mensagemLoading('Recuperando Senha..')
        const data = {
            email: email,
            cpf: cpf
        }
        recuperaSenha(data).then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemAlerta(res.message)
            }else{
                mensagemSucesso('Sua senha é: <b>'+res.senha+'</b>')
            }
        })

    }

    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                    {/* Register */}
                    <div className="card">
                        <div className="card-body">
                        <img src='/images/tvcombate_logo_login.png' style={{ width: '100%' }} />
                            <h4 className="mb-2" style={{ marginTop: 20, textAlign: 'center' }}>Recuperar Senha</h4>
                            <p className="mb-4" style={{ marginTop: 20, textAlign: 'center' }}>Digite abaixo suas credenciais</p>
                            <div className="mb-3">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" value={email} onChange={handleEmail} className="form-control" id="email" name="email-username" placeholder="Digite seu Email" autofocus />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">CPF</label>
                                    <input type="text" value={cpf} onChange={handleCpf} className="form-control" id="email" name="email-username" placeholder="Digite seu CPF" autofocus />
                                </div>
                                <div className="mb-3">
                                    <button onClick={() => esqueciSenha()} className="btn btn-primary d-grid w-100" type="submit">Recuperar</button>
                                    <button onClick={() => window.location = '/' } className="btn btn-default d-grid w-100" type="submit">Já possui Cadastro? Login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}