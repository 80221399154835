import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { listaTransmissoes, deletaTransmissao } from '../../services/mysql'

export default function Produtos() {
    const [academias, setAcademias] = useState([])

    useEffect(() => {
        mensagemLoading('Buscando Transmissões...')
        listaTransmissoes().then((res) => {
            Swal.close()
            setAcademias(res)
        })
    }, {})

    function removeTransmissao(item) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover a transmissão ' + item.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Deletando Transmissão..')
                deletaTransmissao(item.id).then((res) => {
                    if (res.erro) {
                        console.log(res)
                        mensagemErro(res.message)
                    } else {
                        mensagemSucesso('Transmissão removido com sucesso')
                    }
                })

            }
        });
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            mensagemLoading('Atualizando Eventos...')
            listaTransmissoes().then((res) => {
                Swal.close()
                setAcademias(res)
            })
        })
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 " style={{marginTop:20}}>
                                <div className="card">
                                    <div className='row'>
                                        <div className='col-md-10'>
                                            <h5 className="card-header">Transmissões</h5>
                                        </div>
                                        <div className='col-md-2' style={{ padding: 10 }}>
                                            <a href='/cadastroTransmissao' className="btn btn-primary me-2" >
                                                Nova
                                            </a>
                                        </div>
                                    </div>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Imagem</th>
                                                    <th>Transmissão</th>
                                                    <th>Valor</th>
                                                    <th>Status</th>
                                                    <th>Ação</th>
                                                    <th>Remover</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {academias.map((transmissao) =>
                                                    <tr key={transmissao.id}>
                                                        <td>{<img src={transmissao.urlImagem} alt="user-avatar" className="d-block rounded" height={50} width={50} id="uploadedAvatar" />}</td>
                                                        <td>{transmissao.nome}</td>
                                                        <td>{transmissao.valor? transmissao.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }):0}</td>
                                                        <td>
                                                            {transmissao.status === 'ATIVO' ?
                                                                <span class="badge bg-label-success me-1">Ativo</span>
                                                                :
                                                                <span class="badge bg-label-danger me-1">Inativo</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            <button onClick={() => window.location = '/cadastroTransmissao?i=' + transmissao.id} type="button" className="btn rounded-pill btn-icon btn-primary">
                                                                <span className="tf-icons bx bx-search" />
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button onClick={() => removeTransmissao(transmissao)} type="button" className="btn rounded-pill btn-icon btn-danger">
                                                                <span className="tf-icons bx bx-trash" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                                {academias.length === 0 ?
                                                    <p style={{ textAlign: 'center', padding: 10 }} >Sem transmissões cadastradas</p>
                                                    :
                                                    null
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}