import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import CurrencyInput from 'react-currency-input';
import moment from 'moment';
import { criaTransmissao,atualizaTransmissao,buscaTransmissao } from '../../../services/mysql'

export default function CadastroProdutos(props) {
    const [dados] = useState(props.location.state ? props.location.state.produto : '')
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('i'))

    

    // Dados Academia
    const [imagem, setImagem] = useState('')
    const [nomeAcademia, setNomeAcademia] = useState('')
    const [canalTransmissao, setCanalTransmissao] = useState('')
    const [dataTransmissao, setDatalTransmissao] = useState('')
    const [status, setStatus] = useState('')
    const [valor, setValor] = useState(0)

    useEffect(() => {
        if (id) {
            mensagemLoading('Carregando dados..')
            buscaTransmissao(id).then((res) => {
                if (res.erro) {
                    mensagemErro('Erro ao buscar transmissão')
                } else {
                    setImagem(res.transmissao.urlImagem)
                    setNomeAcademia(res.transmissao.nome)
                    setCanalTransmissao(res.transmissao.canalTransmissao)
                    setDatalTransmissao(res.transmissao.dataTransmissao)
                    setValor(res.transmissao.valor)
                    setStatus(res.transmissao.status)
                    Swal.close()
                }
            })
            
        }

    }, [])

    function cadastraAcademia() {
        if (!nomeAcademia) {
            mensagemAlerta('Digite o nome da Academia')
            return
        }
        if (!canalTransmissao) {
            mensagemAlerta('Coloque o canal da transmissão')
            return
        }
        if (!valor || valor === 0) {
            mensagemAlerta('Digite o valor da transmissão')
            return
        }
        if (!status) {
            mensagemAlerta('Escolha o status da academia')
            return
        }
        const data = {
            urlImagem: imagem,
            nome: nomeAcademia,
            canalTransmissao: canalTransmissao,
            dataTransmissao: dataTransmissao,
            valor:valor,
            status: status,
        }
        if (id) {
            mensagemLoading('Atualizando transmissão..')
            atualizaTransmissao(id, data).then((res) => {
                if (res.erro) {
                    mensagemErro(res.message)
                } else {
                    mensagemSucesso(res.message)
                }
            })
        } else {
            mensagemLoading('Cadastrando transmissão..')
            criaTransmissao(data).then((res) => {
                if (res.erro) {
                    console.log(res)
                    mensagemErro('Erro ao cadastrar transmissão')
                } else {
                    mensagemSucesso(res.message)
                }
            })
        }

    }
    


    // Handlers Academia
    const handleImagem = (event) => {
        event.persist();
        setImagem(event.target.value)
    }
    const handleNomeAcademia = (event) => {
        event.persist();
        setNomeAcademia(event.target.value);
    }
    const handleCanalTransmissao = (event) => {
        event.persist();
        setCanalTransmissao(event.target.value);
    }
    const handleDataTransmissao = (event) => {
        event.persist();
        setDatalTransmissao(event.target.value);
    }
    function handleValor(event, maskedvalue, floatvalue) {
        setValor(maskedvalue);
    }
    const handleStatusAcademia = (event) => {
        event.persist();
        setStatus(event.target.value);
    }


    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/transmissoes'
        })
    }
    function mensagemLink(msg) {
        Swal.fire('Copie abaixo seu link', msg, 'success')
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <h5 className="card-header">{id && nomeAcademia ? nomeAcademia : 'Nova Transmissão'}</h5>
                                                </div>
                                            </div>

                                            {/* Account */}
                                            <div className="card-body">
                                                <div onsubmit="return false">
                                                    <div className="row">
                                                        <div className="card-body row">
                                                            <div className="d-flex align-items-start align-items-sm-center gap-4 col-md-4">
                                                                <img src={imagem ? imagem : 'https://static.vecteezy.com/system/resources/previews/028/047/017/non_2x/3d-check-product-free-png.png'} alt="user-avatar" className="d-block rounded" height={200} width={200} id="uploadedAvatar" />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label class="form-label" for="basic-icon-default-fullname">URL da imagem</label>
                                                            <div class="input-group input-group-merge">
                                                                <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                    class="bx bx-image"></i></span>
                                                                <input value={imagem} onChange={handleImagem} type="text" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div class="mb-3 col-md-4">
                                                            <label class="form-label" for="basic-icon-default-fullname">Nome da Transmissão</label>
                                                            <div class="input-group input-group-merge">
                                                                <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                    class="bx bx-tv"></i></span>
                                                                <input value={nomeAcademia} onChange={handleNomeAcademia} type="text" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div class="mb-3 col-md-2" >
                                                            <label class="form-label" for="basic-icon-default-fullname">Valor</label>
                                                            <div class="input-group input-group-merge">
                                                                <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                    class="bx bx-money"></i></span>
                                                                {/* <input onChange={handleValor} value={valor} type="text" class="form-control" /> */}
                                                                <CurrencyInput value={valor} onChange={handleValor} className="form-control" id="email2" prefix="R$ " decimalSeparator="," thousandSeparator="." />
                                                            </div>
                                                        </div>
                                                        <div class="mb-3 col-md-6">
                                                            <label class="form-label" for="basic-icon-default-fullname">Canal de Transmissão</label>
                                                            <input value={canalTransmissao} onChange={handleCanalTransmissao} type="text" class="form-control" />
                                                        </div>
                                                        <div class="mb-3 col-md-3">
                                                            <label class="form-label" for="basic-icon-default-fullname">Data Transmissão</label>
                                                            <input value={dataTransmissao} onChange={handleDataTransmissao} type="datetime-local" class="form-control" />
                                                        </div>
                                                        <div class="mb-3 col-md-2">
                                                            <label class="form-label" for="basic-icon-default-fullname">Status</label>
                                                            <select id="defaultSelect" className="form-select" onChange={handleStatusAcademia} >
                                                                <option>Selecione</option>
                                                                <option value={'ATIVO'} selected={id && status === 'ATIVO' ? 'selected' : null} >Ativo</option>
                                                                <option value={'INATIVO'} selected={id && status === 'INATIVO' ? 'selected' : null} >Inativo </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        {id ?
                                                            <>
                                                                <button onClick={() => cadastraAcademia()} type="submit" className="btn btn-primary me-2">Atualizar Transmissão</button>
                                                            </>
                                                            :
                                                            <button onClick={() => cadastraAcademia()} type="submit" className="btn btn-primary me-2">Cadastra Transmissão</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /Account */}
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}