import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { listaEventos,listaTransmissoes } from '../../../services/mysql'
import moment from 'moment/moment';

export default function Atleta() {
    const [academias, setAcademias] = useState([])
    const [transmissoes, setTransmissoes] = useState([])
    const [tipo, setTipo] = useState('')
    const [search, setSearch] = useState('')

    useEffect(() => {
        mensagemLoading('Buscando Dados...')
        listaEventos().then((res) => {
            Swal.close()
            setAcademias(res)
        })
        listaTransmissoes().then((res) => {
            Swal.close()
            setTransmissoes(res)
        })
    }, {})

    function detalheVideo(data) {
        window.location = `/videosAtleta?i=${data.id}&&a=${data.nome}`
    }
    function detalheTransmissao(data) {
        window.location = `/transmissaoAtleta?i=${data.id}&&a=${data.nome}`
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                {/* <Menu /> */}
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            {tipo === 'eventos' ?
                                <div className="container-xxl flex-grow-1 container-p-y">
                                    <h3><b>Eventos</b></h3>
                                    <p onClick={()=> setTipo('')}>Tela Inicial</p>
                                    {academias && academias.length > 0 ?
                                        <div className="row">
                                            {academias.map((academia) =>
                                                <div className="col-sm-6 col-lg-6 mb-4">
                                                    <div className="card card-border-shadow-primary h-100">
                                                        <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                            <img style={{ width: 100, height: 100 }} src={academia.imagem} />
                                                            <h5 style={{ marginTop: 20 }}>{academia.nome}</h5>
                                                            <p className="mb-1">{academia.descricao}</p>
                                                            <button onClick={() => detalheVideo(academia)} style={{ marginTop: 20 }} className="btn btn-primary d-grid w-100" type="submit">Ver vídeos</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                : tipo === 'transmissao' ?
                                    <div className="container-xxl flex-grow-1 container-p-y">
                                        <h3><b>Transmissões Ao vivo</b></h3>
                                        <p onClick={()=> setTipo('')}>Tela Inicial</p>
                                        {transmissoes && transmissoes.length > 0 ?
                                            <div className="row">
                                                {transmissoes.map((academia) =>
                                                    <div className="col-sm-6 col-lg-6 mb-4">
                                                        <div className="card card-border-shadow-primary h-100">
                                                            <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                                <img style={{ width: 100, height: 100 }} src={academia.urlImagem} />
                                                                <h5 style={{ marginTop: 20 }}>{academia.nome}</h5>
                                                                <p className="mb-1">{"A transmissão ocorrerá dia "+moment(academia.dataTransmissao).format('DD/MM/YYYY')}</p>
                                                                <button onClick={() => detalheTransmissao(academia)} style={{ marginTop: 20 }} className="btn btn-primary d-grid w-100" type="submit">Acessar Transmissão</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    <div className="container-xxl flex-grow-1 container-p-y">
                                        <h3><b>Olá, bem vindo</b></h3>
                                        <div className="row">
                                            <div className="col-sm-6 col-lg-6 mb-4">
                                                <div className="card card-border-shadow-primary h-100">
                                                    <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                        {/* <img style={{ width: 100, height: 100 }} src={academia.imagem} /> */}
                                                        <h5 style={{ marginTop: 20 }}>Transmissões</h5>
                                                        <p className="mb-1">Assista as melhores transmissões ao vivo</p>
                                                        <button onClick={() => setTipo('transmissao')} style={{ marginTop: 20 }} className="btn btn-primary d-grid w-100" type="submit">Ver Transmissões</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-6 mb-4">
                                                <div className="card card-border-shadow-primary h-100">
                                                    <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                        {/* <img style={{ width: 100, height: 100 }} src={academia.imagem} /> */}
                                                        <h5 style={{ marginTop: 20 }}>Eventos</h5>
                                                        <p className="mb-1">Assista as melhores eventos</p>
                                                        <button onClick={() => setTipo('eventos')} style={{ marginTop: 20 }} className="btn btn-primary d-grid w-100" type="submit">Ver Eventos</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }


                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}