const url = 'https://app-3prv6jubba-uc.a.run.app'
// const url = 'https://api.tvcombate.com'
// const url = 'http://localhost:3001'
export const login = (dados) => {
    const URL = url+'/login'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(dados)
    }).then((response) => response.json());
}
export const logout = (dados) => {
    const URL = url+'/logout'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(dados)
    }).then((response) => response.json());
}
export const cadastro = (dados) => {
    const URL = url+'/cadastro'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(dados)
    }).then((response) => response.json());
}
export const recuperaSenha = (dados) => {
    const URL = url+'/recuperaSenha'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(dados)
    }).then((response) => response.json());
}

// Eventos
export const listaEventos = (dados) => {
    const URL = url+'/eventos'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'GET'
    }).then((response) => response.json());
}
export const criaEvento = (dados) => {
    const URL = url+'/eventos'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(dados)
    }).then((response) => response.json());
}

export const buscaEvento = (id) => {
    const URL = url+`/evento/${id}`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'GET',
    }).then((response) => response.json());
}

export const atualizaEvento = (id, data) => {
    const URL = url+`/evento/${id}`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'PUT',
        body: JSON.stringify(data)
    }).then((response) => response.json());
}

export const deletaEvento = (id) => {
    const URL = url+`/evento/${id}`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'DELETE',
    }).then((response) => response.json());
}

// Vídeos
export const criaVideo = (dados) => {
    const URL = url+'/videos'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(dados)
    }).then((response) => response.json());
}
export const atualizaVideo = (dados) => {
    const URL = url+'/videos/'+dados.id
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'PUT',
        body: JSON.stringify(dados)
    }).then((response) => response.json());
}
export const buscaVideos = (id) => {
    const URL = url+`/evento/${id}/videos`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'GET',
    }).then((response) => response.json());
}
export const removeVideo = (id) => {
    const URL = url+`/videos/${id}`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'DELETE',
    }).then((response) => response.json());
}

// Transmissões
export const listaTransmissoes = (dados) => {
    const URL = url+'/transmissoes'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'GET'
    }).then((response) => response.json());
}
export const criaTransmissao = (dados) => {
    const URL = url+'/transmissao'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(dados)
    }).then((response) => response.json());
}

export const buscaTransmissao = (id) => {
    const URL = url+`/transmissao/${id}`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'GET',
    }).then((response) => response.json());
}

export const atualizaTransmissao = (id, data) => {
    const URL = url+`/transmissao/${id}`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'PUT',
        body: JSON.stringify(data)
    }).then((response) => response.json());
}
export const deletaTransmissao = (id) => {
    const URL = url+`/transmissao/${id}`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'DELETE',
    }).then((response) => response.json());
}
export const verificaTransmissao = (idUser, idTransmissao) => {
    const URL = url+`/verificaTransmissao/${idUser}/${idTransmissao}`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'GET',
    }).then((response) => response.json());
}
export const criaTransmissaoUser = (data) => {
    const URL = url+`/cadastraTransmissaoUser`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'POST',
        body:JSON.stringify(data)
    }).then((response) => response.json());
}

// Atleta
export const listaVideoUser = (idUser, idEvento) => {
    const URL = url+`/eventos/${idEvento}/videos/${idUser}`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'GET',
    }).then((response) => response.json());
}
export const verificaVideo = (idUser, idVideo) => {
    const URL = url+`/verificaVideo/${idUser}/${idVideo}`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'GET',
    }).then((response) => response.json());
}
export const criaVideoUser = (data) => {
    const URL = url+`/cadastraVideoUser`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'POST',
        body:JSON.stringify(data)
    }).then((response) => response.json());
}

// Pagamento
export const criaPix = (dados) => {
    const URL = url+'/geraPix'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method:'POST',
        body:JSON.stringify(dados)
    }).then((response) => response.json());
}
export const buscaPagamento = (pagamento) => {
    const URL = 'https://api.mercadopago.com/v1/payments/'+pagamento
    return fetch(URL, {
        headers: {
            "Authorization": `Bearer APP_USR-6937144551299254-090318-c513153f0bb99af7eaec3d14399a4322-160993167`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method:'GET'
    }).then((response) => response.json());
}

export const buscaPagamentos = (pagamento) => {
    const { anoMes, idEvento, tipoEvento } = pagamento;

    // Cria um array de parâmetros para montar a query string dinamicamente
    let params = new URLSearchParams();

    if (anoMes) {
        params.append('anoMes', anoMes);
    }
    if (idEvento) {
        params.append('idEvento', idEvento);
    }
    if (tipoEvento) {
        params.append('tipoEvento', tipoEvento);
    }

    // Concatena os parâmetros dinamicamente à URL
    const URL = `${url}/listarPagamentos?${params.toString()}`;

    // Executa a requisição GET com a URL montada
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'GET'
    }).then((response) => response.json());
}

// Usuários
export const listaUsuarios = (dados) => {
    const URL = url+'/usuarios'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'GET'
    }).then((response) => response.json());
}
export const novaSenha = (dados) => {
    const URL = url+'/novaSenha'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method:'PUT',
        body:JSON.stringify(dados)
    }).then((response) => response.json());
}
export const deletaUsuario = (id) => {
    const URL = url+`/usuarios/${id}`
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: 'DELETE',
    }).then((response) => response.json());
}

// Outros Serviços
export const downlaodVideo = (dados) => {
    const URL = url+'/downloadVideo'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method:'POST',
        body:JSON.stringify(dados)
    }).then((response) => response.json());
}