import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { listaUsuarios,deletaUsuario } from '../../services/mysql'

export default function Usuarios() {
    const [usuarios, setUsuarios] = useState([])

    useEffect(() => {
        mensagemLoading('Buscando Usuarios...')
        listaUsuarios().then((res) => {
            Swal.close()
            setUsuarios(res)
        })
    }, {})

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    function removeUsuario(item) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover o usuario ' + item.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Deletando Usuario..')
                deletaUsuario(item.id).then((res) => {
                    if (res.erro) {
                        mensagemErro(res.message)
                    } else {
                        mensagemSucesso('Usuário removido com sucesso')
                    }
                })

            }
        });
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            mensagemLoading('Atualizando Usuários...')
            listaUsuarios().then((res) => {
                Swal.close()
                setUsuarios(res)
            })
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 " style={{marginTop:20}}>
                                <div className="card">
                                    <div className='row'>
                                        <div className='col-md-10'>
                                            <h5 className="card-header">Usuários</h5>
                                        </div>
                                    </div>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>CPF</th>
                                                    <th>Email</th>
                                                    <th>Perfil</th>
                                                    <th>Deletar</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {usuarios.map((usuario) =>
                                                    <tr key={usuario.id}>
                                                        <td>{usuario.nome}</td>
                                                        <td>{usuario.cpf}</td>
                                                        <td>{usuario.email}</td>
                                                        <td>{usuario.perfil}</td>
                                                        <td>
                                                            <button onClick={() => usuario.perfil !== 'ADMIN'? removeUsuario(usuario) : null} type="button" className="btn rounded-pill btn-icon btn-danger">
                                                                <span className="tf-icons bx bx-trash" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                                {usuarios.length === 0 ?
                                                    <p style={{ textAlign: 'center', padding: 10 }} >Sem usuários cadastrados</p>
                                                    :
                                                    null
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}