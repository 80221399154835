import React, { useRef, useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import Modal from './Modal';

import { buscaTransmissao, verificaTransmissao, criaTransmissaoUser, criaPix, buscaPagamento } from '../../../services/mysql'
import ModalPix from './ModalPix';

export default function Videos() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('i'))
    const [academia] = useState(queryParams.get('a'))
    const [transmissao, setTransmissão] = useState({})

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [isModalOpenPix, setIsModalOpenPix] = useState(false);
    const openModalPix = () => setIsModalOpenPix(true);
    const closeModalPix = () => setIsModalOpenPix(false);

    const [termo, setTermo] = useState(true)

    const [urlVideo, setUrlVideo] = useState('')

    // Pagamento
    const [qrcode, setQrcode] = useState('')
    const [copiaCola, setCopiaCola] = useState('')
    const intervalId = useRef(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userd7'))
        mensagemLoading('Buscando Dados...')
        buscaTransmissao(id).then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemErro('Erro ao buscar vídeos')
            } else {
                Swal.close()
                setTransmissão(res.transmissao)
            }
        })
    }, {})

    const startApiCalls = (pagamento, transmissao) => {
        fetchApiData(pagamento, transmissao);
        intervalId.current = setInterval(() => {
            fetchApiData(pagamento, transmissao);
        }, 10000);
    };

    const fetchApiData = async (pagamento, transmissao) => {
        try {
            buscaPagamento(pagamento).then((res) => {
                if (res.status === 'approved') {
                    const user = JSON.parse(localStorage.getItem('userd7'))
                    const data = {
                        idTransmissao: id,
                        idUser: user.id
                    }
                    criaTransmissaoUser(data).then((resTransmissao) => {
                        if (resTransmissao.erro) {
                            mensagemErro('Erro ao gerar transmissaão')
                        } else {
                            closeModalPix()
                            setUrlVideo(transmissao.canalTransmissao)
                            openModal()
                            // mensagemSucesso('Seu download já foi iniciado!')
                            // handleDownload(video.urlDownload)
                        }
                    })
                    clearInterval(intervalId.current);
                }
            });
        } catch (err) {
            mensagemErro('Erro ao realizar pagamento');
        }
    };

    function realizaTransmissao() {
        const user = JSON.parse(localStorage.getItem('userd7'))
        mensagemLoading('Verificando..')
        verificaTransmissao(user.id, id).then((res) => {
            if (res.erro) {
                mensagemErro('Erro ao verificar transmissao')
            } else {
                if (res.transmissao) {
                    Swal.close()
                    setUrlVideo(transmissao.canalTransmissao)
                    openModal()
                } else {
                    Swal.fire({
                        title: 'Aviso',
                        text: 'Você precisa realizar o pagamento de ' + transmissao.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) + ' para assistir a transmissão ' + transmissao.nome + '! Deseja realizar o pagamento?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Pagar',
                        cancelButtonText: 'Agora Não'
                    }).then((result) => {
                        if (result.value) {
                            const dados = {
                                nome: 'TVCombate - Transmissão',
                                cpf: user.cpf,
                                email: user.email,
                                descricao: 'Transmissao - ' + transmissao.nome,
                                valor: parseFloat(transmissao.valor),
                            }
                            mensagemLoading('Gerando pix..')
                            criaPix(dados).then((res) => {
                                setQrcode(res.data.pagamento.qrcode)
                                setCopiaCola(res.data.pagamento.chave)
                                Swal.close()
                                startApiCalls(res.data.id, transmissao)
                                openModalPix()
                            })
                        }
                    });
                }
            }
        })
    }

    const extractFileIdGoogle = (url) => {
        const regex = /\/d\/(.*?)\//;  // Expressão regular para pegar o ID entre "/d/" e "/"
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const handleDownload = (urlVideo) => {
        if (!urlVideo) {
            alert('A URL do vídeo é necessária');
            return;
        }

        // Cria dinamicamente um link para fazer a requisição ao backend com a URL do vídeo
        const link = document.createElement('a');
        link.href = `https://app-3prv6jubba-uc.a.run.app/downloadVideo?url=${encodeURIComponent(urlVideo)}`;  // Envia a URL como query parameter

        // Força a abertura da janela de download
        link.target = '_blank';  // Abre o link em uma nova aba (alguns navegadores tratam isso como uma nova ação de download)
        link.download = '';      // O atributo download vazio forçará o diálogo de download para o usuário escolher onde salvar

        // Simula o clique no link para iniciar o download
        document.body.appendChild(link);
        link.click();

        // Remove o link do DOM após o clique
        document.body.removeChild(link);
    };

    function assitirVideo(item) {
        setUrlVideo(item)
        openModal()
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                {/* <Menu /> */}
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h3><b>{academia}</b></h3>
                                <button onClick={() => window.location = '/homeAtleta'} style={{ marginTop: 20, width: '30%' }} className="btn btn-primary d-grid " type="submit">{'< Voltar'}</button>
                                <div className="col-sm-12 col-lg-12 mb-4" style={{ marginTop: 20 }}>
                                    <div className="card card-border-shadow-primary h-100">
                                        {transmissao && transmissao.id ?
                                            <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                <img style={{ width: 200, height: 200 }} src={transmissao.urlImagem} />
                                                <h2 style={{ marginTop: 20 }}>{transmissao.nome}</h2>
                                                <h5>TERMOS DE ACEITE</h5>
                                                <p>Versão : v2</p>
                                                <div style={{ marginTop: 20, alignItems: 'start', textAlign: 'start' }}>
                                                    <h5><b>A Internet:</b></h5>
                                                    <p>A internet ou Operadora de internet fornecida para a Transmissão do Evento não é de responsabilidade da TvCombate e nem do Proprietário do Evento.</p>
                                                    <p>Caso venha ocorrer algum problema, durante a Transmissão do Evento, como: <b>Falta de internet, falta de energia</b> ou <b>qualquer</b> que seja o problema, <b>Não</b> nos responsabilizamos e nem <b>ressarcimos</b> o telespectador.</p>
                                                    <p>O Evento continuará normalmente, sem pausas. Pois está sendo <b>GRAVADO e TRANSMITINDO</b> ao mesmo tempo. Caso o problema seja resolvido, a transmissão continuará de onde parou. </p>
                                                    <h5 style={{ marginTop: 20 }}><b>Responsável sobre PIX:</b></h5>
                                                    <p>O pix recebido cai na conta pague seguro do CEO da Plataforma tvcombate.com e após o Evento e a Transmissão, é repassado para o Dono(a) do Evento, com o valor estipulado pelo Dono do Evento.</p>
                                                    <h5 style={{ marginTop: 20 }}><b>Dúvidas?</b></h5>
                                                    <p>Se vc tiver qualquer dúvida, ao entrar na Plataforma tvcombate.com, basta clicar no ícone do whatsApp e falar diretamente com a pessoa responsável do Evento daquela data, horário e dia.</p>
                                                    <h5 style={{ marginTop: 20 }}><b>Login e Senha:</b></h5>
                                                    <p>A Plataforma tvcombate.com, somente aceita um único Cadastro de login e senha. E válido somente para o dia, horário e data da Transmissão do Evento.</p>
                                                    <h5 style={{ marginTop: 20 }}><b>Vídeos Pós Evento:</b></h5>
                                                    <p>Mais informações sobre os vídeos da Transmissão do Evento, basta acessar periodicamente a Plataforma tvcombate.com no e clicar no ícone play na cor Branca.</p>
                                                    <h5 style={{ marginTop: 20 }}><b>Os vídeos terão algum valor ?</b></h5>
                                                    <p>Somente após Negociação junto ao Contratante do Evento, isto é, Dono(a) do Evento. </p>
                                                    <p>Será possível informarmos na Plataforma tvcombate.com, se os vídeos terão ou não terão algum custo pra Baixar os vídeos. </p>
                                                    <p>Essa decisão é do Contratante do Evento. E Irá constar na Plataforam tvcobmate.com</p>
                                                    <h5 style={{ marginTop: 20 }}><b>PIX Qrcode Código:</b></h5>
                                                    <p>Ao concordar em fazer o pix, o Sistema do Pague Seguro irá gerar um Código e o qrcode. </p>
                                                    <p>Basta copiar, abrir o app do seu banco, colar, fazer o pix e aguardar. </p>
                                                    <p>Pois em alguns segundos a tela será redirecionada para a Tela de Transmissão.</p>
                                                    <p>Tenha CERTEZA em Assistir a Transmissão do Evento com dia, data e horário. Pois a Transação do pix é aceito apenas uma vez, e servirá somente pra você.</p>
                                                    <p><b>Att, Plataforma tvcombate.com</b></p>

                                                </div>
                                                <p>Valor para assistir esta transmissão</p>
                                                <h5 ><b>{transmissao.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></h5>

                                                <button onClick={() => realizaTransmissao()} style={{ marginTop: 20 }} className="btn btn-success d-grid w-100" type="submit">
                                                    <i className="menu-icon tf-icons bx bx-video" />Concordar e Assistir Transmissão
                                                </button>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                                {isModalOpen && urlVideo && <Modal onClose={closeModal} url={urlVideo} />}
                                {isModalOpenPix && <ModalPix textToCopy={copiaCola} qrcode={qrcode} onClose={closeModalPix} />}
                            </div>
                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}