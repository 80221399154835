import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import moment from 'moment';

export default function Admin() {
    const [mes, setMes] = useState('')
    const [ano, setAno] = useState('')
    const [tipo, setTipo] = useState('')
    const [usuario, setUsuario] = useState({})
    const [cotacoes, setCotacoes] = useState([])
    const [agentes, setAgentes] = useState([])

    useEffect(() => {
        
    }, {})

    const handleMes = (event) => {
        event.persist();
        setMes(event.target.value);
    }
    const handleAno = (event) => {
        event.persist();
        setAno(event.target.value);
    }
    const handleTipo = (event) => {
        event.persist();
        setTipo(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 " style={{marginTop:40}}>
                                <div className="card">
                                    <h5 className="card-header">Últimos Pedidos</h5>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Pedido</th>
                                                    <th>Cliente</th>
                                                    <th>Destino</th>
                                                    <th>Valor</th>
                                                    <th>Status</th>
                                                    <th>Ação</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {agentes.map((agente) =>
                                                    <tr key={agente.id}>
                                                        <td>{agente.agente}</td>
                                                        <td>{agente.cnpj}</td>
                                                        <td>{agente.nomeEmpresa}</td>
                                                        <td><span class="badge bg-label-warning me-1">Pendente</span></td>
                                                        <td>
                                                            <button onClick={() => window.location = '/detalheAgente?a=' + agente.id} type="button" className="btn rounded-pill btn-icon btn-primary">
                                                                <span className="tf-icons bx bx-search" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                                {agentes.length === 0 ?
                                                    <p style={{ textAlign: 'center', padding: 10 }} >Sem pedidos</p>
                                                    :
                                                    null
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}