import React from 'react';
import './app.css';

const Modal = ({ onClose, url }) => {
  return (
    <div className="modal">
      <div className="modal-content" >
        <span className="close" onClick={onClose}>&times;</span>
        <iframe width="100%" height="100%" src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <button onClick={onClose} className="btn btn-default d-grid w-100" type="submit">Fechar</button>
      </div>
    </div>
  );
}

export default Modal;
