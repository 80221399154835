import React from 'react';
import './app.css';

const ModalPix = ({ textToCopy,qrcode, onClose }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => alert('Texto copiado com sucesso!'))
      .catch(err => alert('Erro ao copiar texto: ' + err));
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <img style={{ width: '100%', height: '100%', textAlign: 'center' }} src={"data:image/png;base64, " + qrcode} alt="Red dot" />
        <p>{textToCopy}</p>
        <button className="copy-btn" onClick={copyToClipboard}>Copiar Texto</button>
      </div>
    </div>
  );
}

export default ModalPix;
